@charset "UTF-8";
$version: 20200811;

@font-face {
  font-family: 'a1lab';
  src: url('fonts/a1lab.eot?#{$version}');
  src: url('fonts/a1lab.eot?#{$version}#iefix') format('embedded-opentype'),
    url('fonts/a1lab.woff?#{$version}') format('woff'),
    url('fonts/a1lab.ttf?#{$version}') format('truetype'),
    url('fonts/a1lab.svg?#{$version}#a1lab') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin icons-a1 {
  font-family: 'a1lab' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[data-icon]:before {
  @include icons-a1;
}

[class^='a1-']:before,
[class*=' a1-']:before {
  @include icons-a1;
}

/**
 COPY&PASTE the icons definitions below
 */

.a1-lock:before {
  content: '\61';
}
.a1-chevron-right-circle:before {
  content: '\62';
}
.a1-star:before {
  content: '\63';
}
.a1-star-empty:before {
  content: '\64';
}
.a1-eye-crossed:before {
  content: '\65';
}
.a1-folder-star:before {
  content: '\66';
}
.a1-run:before {
  content: '\67';
}
.a1-calendar-insert:before {
  content: '\68';
}
.a1-timer2:before {
  content: '\69';
}
.a1-bubble-text:before {
  content: '\6a';
}
.a1-chevron-up:before {
  content: '\6b';
}
.a1-chevron-down:before {
  content: '\6c';
}
.a1-chevron-left:before {
  content: '\6d';
}
.a1-chevron-right:before {
  content: '\6e';
}
.a1-chevron-left-circle:before {
  content: '\6f';
}
.a1-chevron-down-circle:before {
  content: '\70';
}
.a1-chevron-up-circle:before {
  content: '\71';
}
.a1-star-fill:before {
  content: '\72';
}
.a1-checked:before {
  content: '\73';
}
.a1-search:before {
  content: '\74';
}
.a1-unlock:before {
  content: '\75';
}
.a1-warning:before {
  content: '\76';
}
