.Avatar {
  display: inline-block;
  border-radius: 50%;
  background: no-repeat center;
  background-size: cover;
  max-width: 100%;
  height: auto;

  &--small {
    width: 48px;
    height: 48px;
  }
}
