@import 'src/assets/variables';

.App {
  &--client {
    background-color: $color-bg;
    //padding-left: 0;
    //padding-right: 0;
    //margin: 0;
  }

  &--admin {
    margin: auto;
    background-color: $color-white;
  }

  &-container {
    margin-top: 30px;
  }
}

.AnimatedRoutes {
  position: relative;

  &--admin {
    background-color: $color-white;
  }

  &--client {
    min-height: 100vh;
    margin: 0;
    background-color: $color-bg;
    position: relative;
  }
}

.page-container {
  position: relative;
}

.page {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  min-height: calc(100vh - 48px);
}

.page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
