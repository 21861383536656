$primary-font: 'Arial', sans-serif;
$secondary-font: 'Source Sans Pro', sans-serif;
$extra-font: 'Work Sans', sans-serif;

$color-bg: #f3f5f0;
$color-white: #fff;
$color-gray: #808080;
$color-gray-light: #daddd4;
$color-gray-border: #b2b6aa;
$color-gray-dark: #585e4e;
$color-silver: #efefef;
$color-red: #da291c;
$color-black: #000000;
$color-prince: #6295ac;
$color-prince-light: #e7eff3;
$color-success: #32d04b;
$color-error: #f82f3e;
$color-missing: #ffc107;

$grid-columns: 12 !default;
$grid-gutter-width: 20px !default;
$grid-gutter-width-half: ($grid-gutter-width / 2);

$grid-breakpoint-xs: 0;
$grid-breakpoint-xs-below: $grid-breakpoint-xs - 1;
$grid-breakpoint-sm: 576px;
$grid-breakpoint-sm-below: $grid-breakpoint-sm - 1;
$grid-breakpoint-md: 768px;
$grid-breakpoint-md-below: $grid-breakpoint-md - 1;
$grid-breakpoint-lg: 1024px;
$grid-breakpoint-lg-below: $grid-breakpoint-lg - 1;
$grid-breakpoint-xl: 1180px;
$grid-breakpoint-xl-below: $grid-breakpoint-xl - 1;

$grid-breakpoints: (
  xs: $grid-breakpoint-xs,
  sm: $grid-breakpoint-sm,
  md: $grid-breakpoint-md,
  lg: $grid-breakpoint-lg,
  xl: $grid-breakpoint-xl,
);
