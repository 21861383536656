body,
html {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $primary-font;
  font-size: 14px;
  line-height: 18px;
  background-color: transparent;
}

html,
body {
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
}
