@import 'src/assets/variables';

.WeeklyFeedback {
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  min-height: calc(100vh - 48px);

  &-header {
    margin: 15px auto;
    padding-left: 30px;
    font-weight: 400;
    color: $color-gray-dark;
    font-size: 1.2em;
  }

  &-labels {
    font-size: 0.8em;
    font-family: $extra-font;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    color: $color-gray;
  }

  &-content {
    flex: 1 1;
    flex-direction: column;
    align-items: flex-start;
    background: $color-white;

    .Textarea {
      font-size: 1.2em;
      line-height: 1.2em;
    }
  }

  &-subevaluation {
    font-family: $extra-font;
    line-height: 1;
    margin: 0.5em;
    font-size: 0.8em;
  }
}
