h1,
h2,
h3,
h4,
h5 {
  color: $color-gray;
  text-decoration: none solid rgb(128, 128, 128);
}

h3,
h4,
h5 {
  font-weight: 400;
}
